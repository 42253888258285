<template>
  <img
    :draggable="false"
    :src="assetUrl"
    alt="asset"
    :class="[asset, size, aspectRatio]"
  />
</template>
<script>
export default {
  name: "BaseAsset",
  props: {
    asset: {
      type: String,
      default: "primary",
    },
    size: {
      type: String,
      default: "medium",
    },
  },
  computed: {
    isCurrency() {
      return (
        this.asset === "primary" ||
        this.asset === "secondary" ||
        this.asset === "tertiary"
      );
    },
    aspectRatio() {
      if (this.isCurrency && this.$whitelabel.currencies[this.asset].ratio) {
        return "ratio-" + this.$whitelabel.currencies[this.asset].ratio;
      }
      return "ratio-1x1";
    },
    assetUrl() {
      if (this.isCurrency) {
        return this.$whitelabel.currencies[this.asset].single;
      } else {
        return this.$whitelabel.assets.features[this.asset].icon;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.small.ratio-1x1 {
  width: 1.25rem;
  height: 1.25rem;
}
.medium.ratio-1x1 {
  width: 1.875rem;
  height: 1.875rem;
}
.big.ratio-1x1 {
  width: 3.5rem;
  height: 3.5rem;
}
.small.ratio-4x3 {
  width: 1.25rem;
  height: 1rem;
}
.medium.ratio-4x3 {
  width: 1.875rem;
  height: 1.4rem;
}
.big.ratio-4x3 {
  width: 3.5rem;
  height: 3rem;
}
</style>
