<template>
  <div
    class="reward-item d-inline-flex align-items-center justify-content-center me-1"
  >
    <img draggable="false" :src="rewardIcon" class="reward-icon me-1" />
    <span class="reward-amount primary-accent">{{ rewardAmount }}</span>
  </div>
</template>

<script>
export default {
  name: "RewardItem",
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  computed: {
    rewardType() {
      if (this.item.asset.endsWith("Card")) {
        return "card";
      } else if (this.item.asset.endsWith("Timer")) {
        return "timer";
      } else if (this.item.asset.endsWith("Point")) {
        return "loyalty";
      } else if (this.item.asset.endsWith("Elixir")) {
        return "elixir";
      } else if (this.item.asset.endsWith("Currency")) {
        // will return either "gold" or "crown"
        return this.item.type;
      } else {
        return null;
      }
    },
    rewardAmount() {
      if (this.rewardType === "timer") {
        let boosterMinutes = this.item.amount / 60;
        if (boosterMinutes >= 10) {
          return boosterMinutes + ":00 Min.";
        } else {
          return "0" + boosterMinutes + ":00 Min.";
        }
      } else {
        return this.formatNumber(this.item.amount);
      }
    },
    rewardIcon() {
      if (this.rewardType) {
        switch (this.rewardType) {
          case "card":
            return this.$whitelabel.assets.features.cards.icon;
          case "gold":
            return this.$whitelabel.currencies.primary.single;
          case "crown":
            return this.$whitelabel.currencies.secondary.single;
          case "loyalty":
            return this.$whitelabel.assets.features.loyalty.icon;
          case "elixir":
            return this.$whitelabel.currencies.tertiary.single;
          case "timer":
            return this.$whitelabel.assets.features.booster.icon;
          default:
            return require("@/assets/img/icons/check-green.png");
        }
      } else {
        return require("@/assets/img/icons/check-green.png");
      }
    },
  },
};
</script>
<style lang="scss">
.reward-item {
  min-height: 34px;
  .reward-icon {
    width: 34px;
    height: auto;
  }
  .reward-amount {
    font-size: 18px;
  }
}
</style>
