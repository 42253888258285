<template>
  <div
    class="earning-container-daily mx-auto d-flex align-items-center justify-content-center"
    :class="!hasShield ? 'no-shield' : ''"
  >
    <img
      draggable="false"
      :src="getIcon"
      class="earning-daily-icon"
      :class="earning"
    />

    <div v-if="iconText" class="icon-text text-center">
      {{ iconText }}
    </div>

    <div
      class="earning-btn to-center-abs gradient-indent glow text-center"
      :class="size"
    >
      <div
        class="d-inline-flex align-items-center w-100 justify-content-center"
      >
        <span
          class="earning-amount"
          :class="[
            size,
            hasShield === false ? 'white' : '',
            lockedInfo && lockedInfo === 'unlocked' ? 'green' : 'black',
          ]"
        >
          <slot name="earning-amount-or-text"> </slot>
        </span>
        <BaseAsset
          v-if="earning === 'facebook'"
          class="ms-1"
          :class="[lockedInfo && lockedInfo === 'locked' ? 'disabled' : '']"
          asset="primary"
          size="small"
        >
        </BaseAsset>
      </div>
      <img
        v-if="lockedInfo && lockedInfo === 'unlocked'"
        src="@/assets/img/cards/icon-set-complete.png"
        class="icon-complete icon-left"
      />
      <img
        v-else-if="lockedInfo && lockedInfo === 'locked'"
        src="@/assets/img/common/lock-gold.png"
        class="icon-lock icon-left"
      />
    </div>
  </div>
</template>
<script>
import BaseAsset from "@/components/base/base-asset.vue";
export default {
  name: "EarningShieldDaily",
  components: { BaseAsset },
  props: {
    earning: {
      type: String,
      default: "gold",
    },
    size: {
      type: String,
      default: "",
    },
    lockedInfo: {
      type: String,
      default: "",
    },
    iconText: {
      type: String,
      default: "",
    },
    hasShield: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    getIcon() {
      if (!this.earning) return "";
      switch (this.earning) {
        case "gold":
          return this.$whitelabel.currencies.primary.single;
        case "card":
          return this.$whitelabel.assets.features.cards.icon;
        case "crown":
          return this.$whitelabel.currencies.secondary.single;
        case "loyalty":
          return this.$whitelabel.assets.features.loyalty.accent;
        case "user-league":
          return this.$whitelabel.assets.features.league.icon;
        case "facebook":
          return require("@/assets/img/common/facebook.png");
        default:
          return this.$whitelabel.currencies.primary.single;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.earning-container-daily {
  position: relative;
  height: 100%;
  width: 100%;
  min-height: 119px;
  background: url(~@/assets/img/common/earning-shield-bg.png) center/100% 100%
    no-repeat;
  margin-top: 30px;

  &.no-shield {
    background: transparent;
  }

  .icon-text {
    font-family: Ubuntu-Bold;
    font-size: 28.3px;
    color: white;
    padding-top: 20px;
  }
  .earning-daily-icon {
    max-width: 70%;
    background-color: transparent;
    &.loyalty {
      max-width: 60%;
    }
  }

  .earning-btn {
    width: 160%;
    margin-left: -30%;
    height: 32px;
    bottom: 4%;

    &.lg {
      height: 35px;
    }
    img {
      max-width: 35px;

      &.disabled {
        filter: grayscale(100%) brightness(50%);
      }

      &.icon-complete {
        position: absolute;
        width: 37px;
        top: -2px;
      }

      &.icon-lock {
        position: absolute;
        width: 35px;
        top: -12px;
      }

      &.icon-left {
        left: -10px;
      }

      &.icon-right {
        right: 10px;
        top: 5px;
        height: 22px;
      }
    }
  }

  .earning-amount {
    font-size: 24px;
    line-height: 32px;
  }
  .green {
    color: #43ff00;
    font-family: Ubuntu-Bold;
  }
  .black {
    color: #5e707c;
    font-family: Ubuntu-Bold;
  }
  .white {
    color: #ffffff !important;
  }
}
</style>
