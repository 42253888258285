<template>
  <b-modal
    id="modal-winter-help"
    ref="modal-winter-help"
    :hide-footer="true"
    modal-class="custom-modal1"
    @shown="onOpen"
  >
    <template slot="default">
      <div class="preload"></div>
      <div class="w-100 h-100 bg"></div>
      <!--page 1-->
      <div v-if="currentPage === 1" id="page1">
        <div class="logo">
          <div class="main"></div>
          <div class="bonus" :class="$store.state.locale"></div>
        </div>

        <div class="text1 to-center-abs">
          {{ $t("events.winter.intro") }}
        </div>

        <div class="visual to-center-abs"></div>
        <div class="sweepstakes-text text-center to-center-abs">
          <p class="m-0">{{ $t("events.winter.winner.foot_note") }}</p>
          <p class="m-0">
            <i18next :translation="$t('events.winter.tac.footnote')">
              <template #0>
                <a
                  href="/legal/raffle_tac"
                  target="_blank"
                  rel="noopener noreferrer"
                  >{{ $t("events.winter.tac") }}</a
                ></template
              >
            </i18next>
          </p>
        </div>
      </div>

      <div
        v-else-if="currentPage === 2"
        id="page4"
        class="container-fluid h-100 position-relative"
      >
        <b-row align-h="center" align-v="center" class="h-75 ps-5 py-3 pe-3">
          <div class="logo">
            <div class="main"></div>
            <div class="bonus" :class="$store.state.locale"></div>
          </div>
          <b-col cols="12">
            <div class="headline-gold text-start">
              {{ $t("events.winter.raffle_rewards_intro") }}
            </div>
          </b-col>
          <b-col cols="12" class="px-5 mb-1">
            {{ $t("events.winter.raffle_info") }}
          </b-col>

          <b-col
            v-for="entry in combinedRaffleData"
            :key="entry.id"
            class="d-flex flex-column"
          >
            <div class="fs-6 text-center" :class="`rank-${entry.position}`">
              <p
                class="m-0 bold"
                :class="entry.position !== 1 ? 'primary-accent-glow' : ''"
              >
                {{ entry.amount }}x
              </p>
              <p
                class="m-0 bold"
                :class="entry.position !== 1 ? 'primary-accent-glow' : ''"
              >
                {{ $t(`events.raffle.reward.${entry.asset.title}`) }}
              </p>
            </div></b-col
          >
        </b-row>
      </div>
      <!--page 3-->
      <div
        v-else-if="currentPage === 3"
        id="page3"
        class="container-fluid h-100 position-relative"
      >
        <b-row
          class="h-100 p-3 position-relative"
          align-h="center"
          align-v="center"
        >
          <div class="logo">
            <div class="main"></div>
            <div class="bonus" :class="$store.state.locale"></div>
          </div>
          <b-col cols="7" class="ps-5">
            <div class="text-start">
              <div class="headline-gold">
                {{ $t("events.winter.manual.page3.headline") }}
              </div>

              <div class="mt-4">
                <i18next :translation="$t('events.winter.how_it_works')">
                  <template v-if="eventDays" #0>{{ eventDays }}</template>
                </i18next>
              </div>

              <div class="mt-4">
                <span
                  ><i18next :translation="$t('events.winter.get_premium')">
                    <template #0>{{
                      $t("events.winter.premium_pass")
                    }}</template>
                  </i18next>
                </span>
              </div>
              <div
                v-if="showButton"
                class="mt-3 king-pass-button d-inline-flex align-items-center justify-content-between active clickable"
                @click="$emit('buy-premium')"
              >
                <div class="text1 text-uppercase">
                  <i18next :translation="$t('events.winter.premium_activate')">
                    <template #0>
                      {{ $t("events.winter.premium_pass") }}</template
                    >
                  </i18next>
                </div>

                <div class="text2">
                  {{ price }}
                </div>
                <BaseAsset asset="secondary" class="crown-icon"></BaseAsset>
              </div></div
          ></b-col>
          <b-col
            cols="5"
            class="h-75 d-flex flex-column align-items-center justify-content-between"
          >
            <div class="ticket-img mx-auto w-100 h-100"></div> </b-col
        ></b-row>
      </div>

      <div class="bottom-menu to-center-abs">
        <div v-if="currentPage >= 2" class="arrow left" @click="prevPage"></div>
        <div class="arrow right" @click="nextPage"></div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import eventPopups from "@/mixins/eventPopups.js";
import getRewardsFromPackage from "@/mixins/paymentFunctions.js";
import AnimationFullRewardEarned from "@/components/animation/animation-full-reward-earned";
import dateFunctions from "@/mixins/dateFunctions.js";
import BaseAsset from "../../base/base-asset.vue";

export default {
  name: "ModalWinterHelp",
  components: { BaseAsset },
  mixins: [
    eventPopups,
    getRewardsFromPackage,
    AnimationFullRewardEarned,
    dateFunctions,
  ],
  props: {
    raffleRewards: {
      type: Array,
      default: () => [],
    },
    raffleWinners: {
      type: Array,
      default: () => [],
    },
    eventDays: {
      type: Number,
      default: null,
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    price: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      modalId: "modal-winter-help",
      currentPage: 1,
      maxPages: 3,
    };
  },
  computed: {
    combinedRaffleData() {
      if (!this.raffleRewards || !this.raffleWinners) return null;

      return this.raffleRewards
        .map((reward) => {
          const winner = this.raffleWinners.find(
            (winner) => winner.id === reward.raffle_winner_id
          );

          // Only include matches
          if (winner) {
            return {
              id: winner.id, // or `reward.id` if preferred
              position: winner.position,
              users: winner.users,
              amount: reward.amount,
              asset: reward.asset,
            };
          }
        })
        .filter(Boolean)
        .sort((a, b) => {
          const sortOrder = [3, 1, 2]; // Define the custom sort order
          return sortOrder.indexOf(a.position) - sortOrder.indexOf(b.position);
        });
      // Remove undefined results if there’s no match
    },
  },

  methods: {
    onOpen() {
      this.currentPage = 1;
    },
    prevPage() {
      if (this.currentPage <= 0) {
        this.currentPage = 1;
        return;
      }
      this.currentPage--;
    },
    nextPage() {
      if (this.currentPage >= this.maxPages) {
        this.$bvModal.hide(this.modalId);
        return;
      }
      this.currentPage++;
    },
  },
};
</script>
<style lang="scss">
$modal-width: 822px;
$modal-height: 535px;

#modal-winter-help {
  .preload {
    height: 0;
    width: 0;
    background-image: url(~@/assets/img/events/bundesliga/btn-collect-all-bg-hover.png);
  }

  .modal-dialog {
    min-width: $modal-width;
    max-width: $modal-width;
    width: 100%;
    height: $modal-height;
    margin-top: 80px;

    @media (max-height: 850px), (max-width: 1250px) {
      transform: scale(0.8);
      top: -40px;
    }
    @media (max-height: 770px), (max-width: 1100px) {
      transform: scale(0.7);
      top: -100px;
    }
    @media (max-width: 1100px) {
      left: -5%;
    }
  }

  .modal-body {
    width: 100%;
    height: 100%;
    overflow: visible;
    padding: 0;

    .bg {
      position: absolute;
      left: 0;
      top: 0;
      background: url(~@/assets/img/events/winter-bonus-jagd/manual-bg-main.png)
        center/100% 100% no-repeat;
    }
  }

  .modal-header {
    visibility: hidden;
  }
  .sweepstakes-text {
    line-height: 1;
    color: rgb(174, 158, 158);
    a {
      color: $text-accent !important;
      &:hover {
        filter: $hover-shadow;
      }
    }
    p {
      font-size: 14px;
    }
    bottom: 20px;
  }

  .king-pass-button {
    width: 400px;
    height: 50px;
    padding-left: 10px;
    padding-right: 10px;
    background: linear-gradient(
        to bottom,
        #ffffff,
        #48c919 20%,
        #2dac00 47%,
        $text-shadow-dark-green
      ),
      linear-gradient(
        to bottom,
        $bg-gradient-golden,
        #f7ffff 41%,
        #ffee51 75%,
        $bg-gradient-golden
      );
    border: double 2px transparent;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    border-radius: 25.2px;

    &.active {
      &:hover {
        background: linear-gradient(
            to bottom,
            $text-shadow-dark-green,
            #2dac00 53%,
            #48c919 80%,
            #ffffff
          ),
          linear-gradient(
            to bottom,
            $bg-gradient-golden,
            #f7ffff 41%,
            #ffee51 75%,
            $bg-gradient-golden
          );

        border: double 2px transparent;
        background-origin: border-box;
        background-clip: padding-box, border-box;
        border-radius: 25.2px;
      }
    }
    .text1 {
      font-size: 16px;
      text-shadow: 0 0 4px $drop-shadow-dark;
      font-family: kanit-semibold, sans-serif;
      line-height: 1;
    }

    .text2 {
      font-family: kanit-semibold, sans-serif;
      font-size: 23px;
      text-shadow: 0 0 4px $drop-shadow-dark;
      text-align: right;
    }
    .crown-icon {
      width: 52px;
      height: 42px;
    }
  }

  .modal-content {
    width: 100%;
    height: 100%;
    background: none;
    font-size: 21px;
    line-height: 1.5;

    .bottom-menu {
      top: 50%;
      width: 77%;

      .arrow {
        position: absolute;
        top: -35px;
        width: 68px;
        height: 68px;
        cursor: pointer;
        &:hover {
          filter: $hover-shadow;
        }

        &.left {
          background: url(~@/assets/img/events/winter-bonus-jagd/btn-slide-arrow.png)
            bottom center/100% 100% no-repeat;
          left: -120px;
          transform: scaleX(-1);
        }

        &.right {
          background: url(~@/assets/img/events/winter-bonus-jagd/btn-slide-arrow.png)
            bottom center/100% 100% no-repeat;
          right: -120px;
        }
      }
    }

    .logo {
      z-index: 30;
      .main {
        position: absolute;
        left: 13px;
        top: 0;
        z-index: 20;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-logo-main.png)
          right/100% 100% no-repeat;
      }

      .bonus {
        position: absolute;
        left: 0;
        top: 84px;
        z-index: 21;

        &.en {
          background: url(~@/assets/img/events/winter-bonus-jagd/logotype-en.png)
            right/100% 100% no-repeat;
        }
        &.de {
          background: url(~@/assets/img/events/winter-bonus-jagd/logotype-de.png)
            right/100% 100% no-repeat;
        }
      }
    }

    .headline-gold {
      font-family: Ubuntu-Bold;
      font-size: 30px;
      color: white;
      text-transform: uppercase;
      line-height: 1.3;
      background-image: linear-gradient(
        to bottom,
        #fef7bb,
        #f9bc19 55%,
        #da8408 70%
      );
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @supports not (-webkit-background-clip: text) {
        background: transparent;
        color: $popup-text-light-gold;
      }

      // Safari (7.1+)
      _::-webkit-full-page-media,
      _:future,
      :root & {
        -webkit-text-fill-color: #ffe824 !important;
      }
    }

    #page1 {
      .logo {
        position: absolute;
        left: 210px;
        top: -60px;

        .main {
          width: 304px;
          height: 132px;
        }

        .bonus {
          width: 340px;
          height: 120px;
        }
      }

      .text1 {
        top: 145px;
        padding: 0 101px;
      }

      .visual {
        bottom: 45px;
        width: 712px;
        height: 218px;
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-manual-1-visual.png)
          right/100% 100% no-repeat;
      }
    }

    #page3 {
      .logo {
        position: absolute;
        left: 475px;
        top: -63px;

        .main {
          width: 272px;
          height: 118px;
        }

        .bonus {
          width: 304px;
          height: 107px;
          top: 75px;
        }
      }

      .ticket-img {
        background: url(~@/assets/img/events/winter-bonus-jagd/wbj-manual-4-visual.png)
          center/100% no-repeat;
      }
    }

    #page4 {
      background: url(~@/assets/img/events/winter-bonus-jagd/wbj-winscreen-rewards-2022.png)
        50% 95%/95% no-repeat;
      .rank-1 {
        margin-top: -75px;
        transform: rotate(5deg);
        p {
          font-size: 18px;
          color: #ff9903;
          text-shadow: 0 0 2px $btn-drop-shadow-blue-modern,
            0 0 2px $drop-shadow-dark, 0 0 8px #ff9903;
        }
      }
      .rank-2 {
        transform: rotate(-6deg);
        margin-left: -85px;
      }
      .rank-3 {
        transform: rotate(10deg);
        margin-right: -30px;
      }
      .logo {
        position: absolute;
        left: 475px;
        top: -63px;

        .main {
          width: 272px;
          height: 118px;
        }

        .bonus {
          width: 304px;
          height: 107px;
          top: 75px;
        }
      }
    }
  }
}
</style>
