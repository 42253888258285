<template>
  <b-modal
    :id="modalId"
    modal-class="alert-window"
    :hide-footer="true"
    :hide-header="!titleText"
    @hidden="resetModal"
  >
    <template #modal-header>
      <div
        v-if="titleText"
        role="button"
        href="#"
        class="close-button"
        @click="handleCancel"
      ></div>
      <div>{{ titleText }}</div>
    </template>
    <template slot="default">
      <div class="modal-contents">
        <div
          v-if="!titleText"
          role="button"
          href="#"
          class="close-button"
          @click="handleCancel"
        ></div>
        <!-- <p class="modal-prompt mt-3">{{ alertText }}</p> -->
        <p class="modal-prompt mt-3" v-html="alertText"></p>
        <div class="d-flex justify-content-center w-75 mb-3 mx-auto">
          <button
            v-if="twoButtons"
            class="cancel-button-red round-new"
            @click="handleCancel"
          >
            {{ cancelButtonText }}
          </button>
          <button class="ok-button-green ms-4 round-new" @click="handleConfirm">
            {{ confirmButtonText }}
          </button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: "AlertWindow",
  computed: {
    alertText() {
      return this.$store.state.popups.alertWindow.alertText;
    },
    twoButtons() {
      return this.$store.state.popups.alertWindow.twoButtons;
    },
    confirmButtonText() {
      let textInStore = this.$store.state.popups.alertWindow.confirmButtonText;
      if (textInStore) {
        return textInStore;
      } else {
        return "Ok";
      }
    },
    cancelButtonText() {
      let textInStore = this.$store.state.popups.alertWindow.cancelButtonText;
      if (textInStore) {
        return textInStore;
      } else {
        return "Cancel";
      }
    },
    redirectUrl() {
      return this.$store.state.popups.alertWindow.redirectUrl;
    },
    modalId() {
      return this.$store.state.popups.alertWindow.modalId;
    },
    titleText() {
      return this.$store.state.popups.alertWindow.titleText;
    },
  },
  mounted() {
    if (this.modalId) {
      this.$bvModal.show(this.modalId);
    }
  },
  methods: {
    handleConfirm() {
      this.$bvModal.hide(`${this.modalId}`);
      this.$root.$emit(`ok-${this.modalId}`);

      if (this.redirectUrl) {
        this.$router.push(this.redirectUrl);
      }
    },
    handleCancel() {
      this.$bvModal.hide(`${this.modalId}`);
      this.$root.$emit("cancel");
    },
    resetModal() {
      this.$store.dispatch("popups/clearModalContent");
    },
  },
};
</script>
<style lang="scss">
.alert-window {
  .close-button {
    right: -16px;
    top: -16px;
    width: 32px;
    height: 32px;
  }
  .modal-header {
    border: none !important;
    justify-content: center;
    text-transform: uppercase;
    font-size: 22px;
    color: $popup-text-light-gold;
  }
  .modal-content {
    color: white;
    text-align: center;
    background-color: transparent;
    background-image: linear-gradient(#123445, $bg-gradient-navy-blue);
    border: solid 2px $primary-accent;
    border-radius: 16px;
    box-shadow: 0 0 60px rgba(77, 229, 255, 0.66),
      0 0 30px rgba(77, 229, 255, 0.66), 0 0 15px $drop-shadow-dark,
      0 0 15px $drop-shadow-dark, 0 0 4px $drop-shadow-dark;
    background-origin: border-box;
    background-clip: padding-box, border-box;
    background-size: 100% 100%;
  }
  .modal-body {
    padding: 0;
  }

  .modal-contents {
    width: 100%;

    min-height: 128px;
    .modal-prompt {
      padding: 8px;
      font-size: 18px;
    }
  }
}
</style>
