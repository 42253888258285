<template>
  <div ref="customCard" class="custom-card">
    <div ref="card" class="w-100 h-100 position-relative">
      <!--            <div ref="cardCont" class="cardCont" @click="animateFlip(true)">-->
      <div ref="cardCont" class="cardCont">
        <div ref="cardBack" class="cardBack">
          <img
            v-if="front"
            draggable="false"
            :src="require('@/assets/img/daily-login/cards/' + front + '.png')"
            class="w-100 h-100 cardimage"
            @load="imageLoaded = true"
          />

          <div v-if="rewards" class="rewards w-100 h-100">
            <div v-if="rewards.length === 1" class="one-rewards">
              <div class="top-bar to-center-abs">
                <div ref="icon1" class="top-bar-icon to-center-abs">
                  <img
                    draggable="false"
                    :src="getTripleSrc(rewardTypes[0])"
                    class="w-100"
                  />
                </div>
                <div class="top-bar-bg to-center-abs" :class="color"></div>
                <div ref="reward1" class="top-bar-amount to-center-abs">
                  +{{ formatNumber(rewards[0].amount) }}
                </div>
              </div>
            </div>

            <div v-if="rewards.length === 2" class="two-rewards">
              <div class="top-bar to-center-abs">
                <div
                  ref="icon1"
                  class="top-bar-icon to-center-abs"
                  :name="rewardTypes[0]"
                >
                  <img
                    draggable="false"
                    class="w-100"
                    :src="getSrc(rewardTypes[0])"
                  />
                </div>
                <div class="top-bar-bg to-center-abs" :class="color"></div>
                <div ref="reward1" class="top-bar-amount to-center-abs">
                  +{{ formatNumber(rewards[0].amount) }}
                </div>
              </div>

              <div class="bottom-bar to-center-abs">
                <div ref="icon2" class="bottom-bar-icon to-center-abs">
                  <img
                    draggable="false"
                    class="w-100"
                    :src="getSrc(rewardTypes[1])"
                  />
                </div>

                <div class="bottom-bar-bg to-center-abs" :class="color"></div>
                <div ref="reward2" class="bottom-bar-amount to-center-abs">
                  +{{ formatNumber(rewards[1].amount) }}
                </div>
              </div>
            </div>

            <div v-if="rewards.length === 3" class="three-rewards w-100 h-100">
              <div class="top-bar to-center-abs">
                <div ref="icon1" class="top-bar-icon to-center-abs">
                  <img :src="getSrc(rewardTypes[0])" class="w-100" />
                </div>
                <div class="top-bar-bg to-center-abs" :class="color"></div>
                <div ref="reward1" class="top-bar-amount to-center-abs">
                  +{{ formatNumber(rewards[0].amount) }}
                </div>
              </div>

              <div class="bottom-bar to-center-abs">
                <div ref="icon2" class="bottom-bar-icon1">
                  <img :src="getSrc(rewardTypes[1])" class="w-100" />
                </div>
                <div ref="icon3" class="bottom-bar-icon2">
                  <img :src="getSrc(rewardTypes[2])" class="w-100" />
                </div>
                <div class="bottom-bar-bg to-center-abs" :class="color"></div>
                <div ref="reward2" class="bottom-bar-amount1">
                  +{{ formatNumber(rewards[1].amount) }}
                </div>
                <div ref="reward3" class="bottom-bar-amount2">
                  +{{ formatNumber(rewards[2].amount) }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div ref="cardFront" class="cardFront">
          <img
            draggable="false"
            src="@/assets/img/daily-login/cards/cardBg.png"
            class="w-100 h-100"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import AnimationSpineRewardFlyCustom from "@/components/animation/animation-spine-reward-fly-custom";
import BaseAsset from "../base/base-asset.vue";

export default {
  name: "CardDailyLogin",
  mixins: [AnimationSpineRewardFlyCustom],
  props: {
    flipped: { type: Boolean, default: false },
    clickCounter: { type: Number, default: 0 },
    index: { type: Number, default: 0 },
    doAnimation: { type: Boolean, default: false },
    rewards: { type: Array, default: null },
  },
  data() {
    return {
      imageLoaded: 0,
    };
  },

  computed: {
    front: function () {
      if (!this.flipped && !this.doAnimation && !this.color) {
        return "";
      }
      const arr_number = [2, 3, 4, 5, 6, 7, 8, 9, 10, "b", "d", "k", "a"];
      const arr_type = ["pik", "karo", "kreuz", "herz"];

      const ran_type = arr_type[Math.floor(Math.random() * arr_type.length)];
      const ran_number =
        arr_number[Math.floor(Math.random() * arr_number.length)];

      return ran_type + "-" + ran_number;
    },

    color: function () {
      if (!this.flipped && !this.doAnimation) {
        return "";
      }

      const arr_type = ["pik", "karo", "kreuz", "herz"];
      const ran_type = arr_type[Math.floor(Math.random() * arr_type.length)];

      let color = "black";
      if (ran_type === "karo" || ran_type === "herz") {
        color = "red";
      }

      return color;
    },

    rewardTypes: function () {
      if (this.rewards === undefined) {
        return;
      }

      let types = [];
      for (let i = 0; i < this.rewards.length; i++) {
        let type = this.rewards[i].type;

        switch (type) {
          case "gold":
            types.push("gold");
            break;
          case "crown":
            types.push("crown");
            break;
          case null:
            types.push("card");
            break;
        }
      }

      return types;
    },
  },

  watch: {
    doAnimation: {
      handler: function () {
        Vue.nextTick(() => {
          this.animateFlip();
        });
      },
    },
  },

  mounted: function () {
    if (this.flipped) {
      this.animateFlip();
    }
  },

  methods: {
    getSrc(rewardType) {
      switch (rewardType) {
        case "gold":
          return this.$whitelabel.currencies.primary.single;
        case "crown":
          return this.$whitelabel.currencies.secondary.single;
        case "card":
          return this.$whitelabel.assets.features.cards.icon;
        default:
          break;
      }
    },
    getTripleSrc(rewardType) {
      switch (rewardType) {
        case "gold":
          return this.$whitelabel.currencies.primary.triple;
        case "crown":
          return this.$whitelabel.currencies.secondary.triple;
        case "card":
          return this.$whitelabel.assets.features.cards.icon;
        default:
          break;
      }
    },
    animateFlip: function (fullanimation = false) {
      //calc center position of window
      let center_pos_x = 440 - (this.index % 7) * 120;
      let center_pos_y = 80;
      if (this.index >= 7) {
        center_pos_y -= 170;
      }

      //calculate the target earning element based on currency
      let earningGold = document.getElementById("daily-earning-gold");
      let earningCrown = document.getElementById("daily-earning-crown");
      let earningCard = document.getElementById("daily-earning-card");
      let earning_containers = document.getElementsByClassName("earning");
      let targets = [];
      for (let i = 0; i < earning_containers.length; i++) {
        if (!this.rewardTypes || this.rewardTypes[i] === null) {
          console.log("Keine Karte gefunden...");
          return;
        }
        switch (this.rewardTypes[i]) {
          case "crown":
            targets[i] = earningCrown;
            continue;
          case "card":
            targets[i] = earningCard;
            continue;
          case "gold":
            targets[i] = earningGold;
            continue;
          default:
            break;
        }
      }

      //config elements
      const { customCard, cardCont, cardFront, cardBack, icon1, icon2, icon3 } =
        this.$refs;
      const timeline = this.gsap.timeline({
        defaults: {
          transformPerspective: 1000,
        },
      });
      this.gsap.set(cardBack, { rotationY: -180 });

      /*ANIMATION BEGINS*/
      //flip
      timeline
        .to(cardFront, 1, { rotationY: 180 })
        .to(cardBack, 1, { rotationY: 0 }, 0)
        .to(cardCont, 0.5, { z: 50 }, 0)
        .to(cardCont, 0.5, { z: 0 }, 0.5);

      // only do animation when flipping on click, not on reloading page
      if (this.doAnimation || fullanimation) {
        //play flip sound
        timeline.to(
          cardCont,
          0,
          {
            onComplete: () => {
              this.playSoundCardFlip();
            },
          },
          0.2
        );

        //card to front
        timeline.to(
          customCard,
          0.5,
          {
            x: center_pos_x,
            y: center_pos_y,
            zIndex: 99 + this.clickCounter,
            scale: 2.3,
            force3D: false,
            rotation: 360,
          },
          0
        );
        //animate icon 1
        if (icon1) {
          timeline
            .to(
              icon1,
              0.01,
              {
                onStart: () => {
                  this.playAnimationSpineRewardFlyDailyLogin(
                    targets[0],
                    this.rewardTypes[0]
                  );
                },
              },
              0.8
            )
            .to(
              icon1,
              0.2,
              {
                scale: 1,
                force3D: false,
                y: -10,
              },
              1
            );
          timeline.to(
            icon1,
            0.1,
            {
              x: -10,
            },
            1.1
          );
          timeline.to(
            icon1,
            0.1,
            {
              x: +10,
            },
            1.1
          );
          timeline.to(
            icon1,
            0.1,
            {
              x: 0,
            },
            1.2
          );
        }

        //animate icon 2
        if (icon2) {
          timeline
            .to(
              icon2,
              0.01,
              {
                onStart: () => {
                  this.playAnimationSpineRewardFlyDailyLogin(
                    targets[1],
                    this.rewardTypes[1]
                  );
                },
              },
              0.8
            )
            .to(
              icon2,
              0.2,
              {
                scale: 1,
                force3D: false,
                y: -10,
              },
              1
            );
          timeline.to(
            icon2,
            0.1,
            {
              x: -10,
            },
            1.1
          );
          timeline.to(
            icon2,
            0.1,
            {
              x: +10,
            },
            1.1
          );
          timeline.to(
            icon2,
            0.1,
            {
              x: 0,
            },
            1.2
          );
        }

        //animate icon 3
        if (icon3) {
          timeline
            .to(
              icon3,
              0.01,
              {
                onStart: () => {
                  this.playAnimationSpineRewardFlyDailyLogin(
                    targets[2],
                    this.rewardTypes[2]
                  );
                },
              },
              0.8
            )
            .to(
              icon3,
              0.2,
              {
                scale: 1,
                force3D: false,
                y: -10,
              },
              1
            );
          timeline.to(
            icon3,
            0.1,
            {
              x: -10,
            },
            1.1
          );
          timeline.to(
            icon3,
            0.1,
            {
              x: +10,
            },
            1.1
          );
          timeline.to(
            icon3,
            0.1,
            {
              x: 0,
            },
            1.2
          );
        }

        // card fly back
        timeline.to(
          customCard,
          0.5,
          {
            x: 0,
            y: 0,
            scale: 1,
          },
          "+=2"
        );
      }
    },
  },
};
</script>

<style scoped lang="scss">
.custom-card {
  width: 112px;
  height: 174px;
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
}
.cardCont {
  height: 100%;
  width: 100%;
  float: left;
  margin-right: 10px;
  position: relative;
}

.cardFront,
.cardBack {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  z-index: 10;
}

.cardFront {
  cursor: pointer;

  &:hover {
    filter: $hover-shadow;
  }
}

.rewards {
  position: absolute;
  top: 0;

  .one-rewards {
    .top-bar {
      width: 90%;
      height: 50%;
      top: 53%;

      .top-bar-bg {
        top: 32.5%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;

        &.black {
          background-image: url("~@/assets/img/daily-login/element1Black@3x.png");
        }

        &.red {
          background-image: url("~@/assets/img/daily-login/element1Red@3x.png");
        }
      }

      .top-bar-icon {
        top: -35%;
        width: 70%;
      }

      .top-bar-amount {
        font-family: Ubuntu-Bold;
        font-size: 10px;
        top: 35%;
      }
    }
  }

  .two-rewards {
    .top-bar {
      width: 90%;
      height: 50%;
      top: 33%;

      .top-bar-bg {
        top: 10%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;

        &.black {
          background-image: url("~@/assets/img/daily-login/element1Black@3x.png");
        }

        &.red {
          background-image: url("~@/assets/img/daily-login/element1Red@3x.png");
        }
      }

      .top-bar-icon {
        top: -20%;
        width: 35%;
      }

      .top-bar-amount {
        font-family: Ubuntu-Bold;
        font-size: 10px;
        top: 13%;
      }
    }

    .bottom-bar {
      width: 90%;
      height: 50%;
      top: 60.5%;

      .bottom-bar-bg {
        top: 16%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;

        &.black {
          background-image: url("~@/assets/img/daily-login/element1Black@3x.png");
        }

        &.red {
          background-image: url("~@/assets/img/daily-login/element1Red@3x.png");
        }
      }

      .bottom-bar-icon {
        position: absolute;
        top: -7%;
        width: 40%;
      }

      .bottom-bar-amount {
        position: absolute;
        font-family: Ubuntu-Bold;
        font-size: 10px;
        top: 20%;
      }
    }
  }

  .three-rewards {
    .top-bar {
      width: 90%;
      height: 50%;
      top: 22%;

      .top-bar-bg {
        top: 31%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;

        &.black {
          background-image: url("~@/assets/img/daily-login/element1Black@3x.png");
        }

        &.red {
          background-image: url("~@/assets/img/daily-login/element1Red@3x.png");
        }
      }

      .top-bar-icon {
        top: 0;
        width: 35%;
      }

      .top-bar-amount {
        font-family: Ubuntu-Bold;
        font-size: 10px;
        top: 33%;
      }
    }

    .bottom-bar {
      width: 90%;
      height: 50%;
      top: 40%;

      .bottom-bar-bg {
        top: 58%;
        height: 100%;
        background-size: 100%;
        background-repeat: no-repeat;

        &.black {
          background-image: url("~@/assets/img/daily-login/element2Black@3x.png");
        }

        &.red {
          background-image: url("~@/assets/img/daily-login/element2Red@3x.png");
        }
      }

      .bottom-bar-icon1 {
        position: absolute;
        top: 33%;
        left: 7%;
        width: 40%;
      }

      .bottom-bar-icon2 {
        position: absolute;
        top: 33%;
        right: 7%;
        width: 40%;
      }

      .bottom-bar-amount1 {
        position: absolute;
        font-family: Ubuntu-Bold;
        font-size: 10px;
        top: 61%;
        left: 20%;
      }

      .bottom-bar-amount2 {
        position: absolute;
        font-family: Ubuntu-Bold;
        font-size: 10px;
        top: 61%;
        right: 20%;
      }
    }
  }
}
</style>
